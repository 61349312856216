<template >
  <div class="table-container">

    <div class="table-toolbar">
      <div class="title">
        <div class="text">
          <span>政策日志</span>
          <!-- <span class="desc">(一级)</span> -->
        </div>
      </div>
      <div class="filters">
       
        <a-button class="filter-btn" type="primary" @click="handleMatch">抓取</a-button>
      </div>
      <!-- <div class="opts">
        <a-button class="opt-btn" size="large" type="link" @click="toCreate">新建</a-button>
      </div> -->
    </div>
    <div class="table">
      <a-table size="middle" :columns="columns" :row-key="record => record.id" :data-source="dataSource"
        :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex == 'icon'">
            <div>
              <FjList :fjlist="record.icon" />
            </div>
          </template>


          <template v-if="column.dataIndex == 'article_num'">
            <div @click="showQy(record)" >
              <span style="color:#3399FF;cursor: pointer;">{{ record.article_num }}</span>
            </div>
          </template>

        </template>
      </a-table>
    </div>
    <LogSubList ref="pplistRef" />
  </div>
</template>
<script>
import { usePagination } from 'vue-request';
import { computed, defineComponent } from 'vue';
import service from '@/service/service';
import { message, Modal } from 'ant-design-vue';
import FjList from '@/components/common/fjList.vue';
import options from '@/util/address-options';
import LogSubList from './components/LogSubList.vue'
export default defineComponent({
  components: {
    FjList,
    LogSubList
  },

  data() {
    return {
      currentTab: '',
      ssqy: '',
      deptList: [],
      areas: options,
      filter: {
        ...this.initFilter,
        title: '',
        dept_id: undefined,
      },
      current: null,
    }
  },
  created() {
    this.getOptions();
  },
  methods: {
    async getOptions() {
      const { data: depts } = await service.get_dept_lists({ limit: 1000 });
      this.deptList = depts.map((m) => ({ label: m.dept_name, value: Number(m.id) }));
    },

    toUrl(item) {
      window.open(item.url)
    },
    showQy(item) {
      console.log(item);
      this.$refs['pplistRef'].init(item.id);
    },
    // 筛选
    handleSearch() {
      let ssqyTemp = {};
      if (this.ssqy?.length) {
        ssqyTemp['sheng'] = this.ssqy[0]?.split('/')[1] || '';
        ssqyTemp['shi'] = this.ssqy[1]?.split('/')[1] || '';
        ssqyTemp['qu'] = this.ssqy[2]?.split('/')[1] || '';
      }
      this.run({
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        ...this.filter,
        ...ssqyTemp,
      })
    },
    edit(item) {
      this.current = item;
      this.$refs['editRef'].onShow();
    },
    handleMatch(item) {
      Modal.confirm({
        title: '警告',
        content: `确认抓取政策?`,
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          const { code, msg } = await service.article_get({ article_id: item.id });
          if (code && code == '1') {
            message.success(msg || '成功');
            this.handleSearch();
          }
          else {
            message.error(msg || '失败');
          }
        }
      });
    },
    toCreate() {
      this.current = { pid: '0' }
      this.$refs['editRef'].onShow();
    },
  },
  setup() {
    // 声明并调用一次数据拉取
    var initFilter = {
      is_match: '0'
    }
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = usePagination((params) => service.article_log({ ...initFilter, ...params }), {
      formatResult: res => {
        res.data.total_num = res.total_number;
        return res.data;
      },
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'limit',
        totalKey: 'total_num',
      },
    });
    // 分页所需的属性
    const pagination = computed(() => ({
      total: total.value,
      current: current.value,
      pageSize: pageSize.value,
    }));

    // data用
    return {
      initFilter,
      dataSource,
      pagination,
      loading,
      columns: [

        {
          title: '执行时间',
          dataIndex: 'input_time',
        },
        {
          title: '完成数量',
          dataIndex: 'complete_num',
        },
        {
          title: '故障网站',
          dataIndex: 'error_num',
        },
        {
          title: '新增政策',
          dataIndex: 'article_num'
        }
      ],
      run,
    };
  },
})
</script>
<style lang="less" scoped>
.table-container {
  .data-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 500px;
    cursor: pointer;
    transition: all .25s ease;

    &:hover {
      color: #3399FF;
    }
  }
}
</style>