<template>
  <a-modal title="已匹配的企业" style="width: 800px;" @ok="submit" :confirm-loading="loading" v-model:visible="flags.visible"
    @cancel="flags.visible = false;" @close="flags.visible = false;">
    <div class="modal-wrap">
      <div class="table">
        <a-table size="middle" :columns="columns" :row-key="record => record.id" :data-source="list"
          :pagination="pagination" :loading="loading" @change="handleChange">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex == 'title'">
              <div class="data-title" @click="toUrl(record)">
                <a-tooltip placement="topLeft"  :title="record.title" :color="'geekblue'">
                  <span>{{ record.title }}</span>
                </a-tooltip>
              </div>
            </template>

            <template v-else-if="column.dataIndex == 'dept_id'">
              <div>
                <span>{{ record.dept_info?.dept_name }}</span>
              </div>
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </a-modal>
</template>
<script>
import service from '@/service/service';
// import { message } from 'ant-design-vue';

export default {
  data() {
    return {
      list: [],
      form: {
        id: '',
      },
      flags: {
        visible: false,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      loading: false,
      columns: [

        {
          title: '部门',
          dataIndex: 'dept_id',
        },
        {
          title: '标题',
          dataIndex: 'title',
          width: 500,
        },
      ],
    }
  },
  methods: {
    toUrl(item) {
      window.open(item.url)
    },
    async init(id) {
      this.form.id = id;
      this.flags.visible = true;
      this.pagination = {
        total: 0,
        current: 1,
        pageSize: 10,
      };
      this.getData();
    },
    async getData() {
      this.loading = true;
      let params = {
        log_id: this.form.id,
        page: this.pagination.current
      };

      const res = await service.get_article_by_logid(params);
      this.pagination = { ...this.pagination, total: res.total_number }
      this.loading = false;
      this.list = res.data || [];
    },
    handleChange(e) {
      this.pagination = { ...e };
      this.getData();
    },
    async submit() {
      this.flags.visible = false;
    }
  }
}
</script>
<style lang="less" scoped>
.modal-wrap {
  .data-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 500px;
    cursor: pointer;
    transition: all .25s ease;

    &:hover {
      color: #3399FF;
    }
  }
}
</style>