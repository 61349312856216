<template>
  <div>
    <div v-for="(item, idx) in fileList" :key="idx">
      <a :href="item.url" :download="item.name">{{ item.name }}</a>
    </div>
  </div>
</template>
<script>
export default {
  props: ['fjlist'],
  data() {
    return {
      fileList: [],
    }
  },
  watch: {
    fjlist: {
      handler(newVal) {
        try {
          this.fileList = newVal ? JSON.parse(decodeURI(newVal)) : [];
        }
        catch (err) {
          this.fileList = [];
        }
        // console.log(this.fileList);
      },
      immediate: true
    }
  }
}
</script>